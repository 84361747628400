<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style lang="scss" scoped>
//.app {
//  width: 100vw;
//  height: 100vh;
//  background-color: #020308;
//  overflow: hidden;
//}



@media screen and (min-width: 900px) {
  .app {
    width: 100vw;
    height: 100vh;
    background-color: #000E67;
    overflow: hidden;
  }
}

@media screen and (max-width: 900px) {
  .app {
    width:400vw;
    background-color: #000E67;
    height: 200vh;
  }
}

</style>
